/* You can add global styles to this file, and also import other style files */

// Your variable overrides can go here, e.g.:
// $h1-font-size: 3rem;

@import "bootswatch/dist/flatly/variables";
@import "bootstrap/scss/bootstrap";
@import "bootswatch/dist/flatly/bootswatch";

@import "ngx-bootstrap/datepicker/bs-datepicker.css";
@import "ngx-toastr/toastr-bs5-alert";
@import "~quill/dist/quill.snow.css";
@import "angular-archwizard/styles/archwizard";

/*  MASTER OVERRIDE */
.modal-lg {
  max-width: 65vw !important;
}

.block-item.block-ui-wrapper--element {
  display: block !important;
  z-index: -1 !important;
  opacity: 0 !important;
  transition: 2s ease-in-out !important;
  /* fade in opacity */
}

/* Make block visible when active */
.block-item.block-ui-wrapper--element.active {
  z-index: 1 !important;
  opacity: 1 !important;
}

.block-ui-template {
  transition: 2s ease-in-out !important;
}

/* default .loading styles, .loading should be invisible, opacity: 0, z-index: -1 */
.loading {
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  text-align: center;
  background-size: cover;
  background: url("/assets/images/front_collage.jpg") no-repeat top center;
}

/* .loading screen is visible when app is not bootstrapped yet, my-app is empty */
app-root:empty + .loading {
  opacity: 1;
  z-index: 100;
}

.login-container {
  height: 100%;
  margin-top: 30vh;
}

/* global has-error fix */
ul.has-error {
  list-style-type: none;
  font-size: 0.8em;
  color: red;
  padding: 0 !important;
  padding-left: 10px !important;
  margin-bottom: 0 !important;
}

.ng-invalid.ng-touched {
  border-color: red;
}

form.submitted .ng-invalid {
  border-color: red;
}

:host ::ng-deep .block-item.block-ui-wrapper--element {
  display: block;
  z-index: -1;
  opacity: 0;
  transition: 2s ease-in-out;
  /* fade in opacity */
}

/* Make block visible when active */
:host ::ng-deep .block-item.block-ui-wrapper--element.active {
  z-index: 1;
  opacity: 1;
}

.ql-size-large {
  font-size: 1.25rem !important;
}

.ql-size-huge {
  font-size: 28px !important;
}

.ql-size-small {
  font-size: 0.6rem !important;
}

.wd-card {
  cursor: pointer;
  transition: opacity 0.1s ease-in-out;
}

.wd-card:hover {
  opacity: 0.7;
}

.card-header {
  text-align: center;
}

.watermark-text {
  display: block;
  position: absolute;
  color: #ff0000;
  transform: translate(-50%, -50%) rotate(-25deg);
  top: 50%;
  left: 50%;
  opacity: 0.5;
  text-align: center;
  padding: 1em;
  border: 3px solid #ff0000;
  border-radius: 4px;
  font-weight: 700;
  z-index: 2;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.widePopover {
  min-width: 444px;
  max-width: 600px;
}

.up-body {
  --e-global-color-primary: #0c2340;
  --e-global-color-primary-light: #0c234033;
  --e-global-color-secondary: #8e8a84;
  --e-global-color-text: #252525;
  --e-global-color-accent: #b4e1d6;
  --e-global-color-success: #279989;
  --e-global-color-footer: #d0cec9;
  --e-global-typography-primary-font-family: "Heebo";
  --e-global-typography-primary-font-size: 35px;
  --e-global-typography-primary-font-size-md: 30px;
  --e-global-typography-primary-font-size-sm: 25px;
  --e-global-typography-primary-font-weight: 600;
  --e-global-typography-primary-text-transform: uppercase;
  --e-global-typography-secondary-font-family: "Heebo";
  --e-global-typography-secondary-font-size: 35px;
  --e-global-typography-secondary-font-size-md: 25px;
  --e-global-typography-secondary-font-size-sm: 25px;
  --e-global-typography-secondary-font-weight: 200;
  --e-global-typography-text-font-family: "Source Serif Pro";
  --e-global-typography-text-font-size: 19px;
  --e-global-typography-text-font-size-md: 17px;
  --e-global-typography-text-font-size-sm: 15px;
  --e-global-typography-text-font-weight: 300;
  --e-global-typography-accent-font-family: "Source Serif Pro";
  --e-global-typography-accent-font-size-lg: 40px;
  --e-global-typography-accent-font-size: 17px;
  --e-global-typography-accent-font-weight: 600;
  --e-icon-list-icon-size: 14px;
  //font-family: var(--e-global-typography-text-font-family), Sans-serif;
  //font-size: var(--e-global-typography-text-font-size);
  //font-weight: var(--e-global-typography-text-font-weight);
  overflow-y: scroll;
  min-height: 100vh;
}

.min-h-100vh {
  min-height: 100vh;
}

.dropdown-item {
  color: inherit;
}

.badge-white {
  background-color: #ffffff;
}

.alert,
.btn,
.form-control,
.list-group-item:first-child,
.list-group-item:last-child {
  border-radius: unset;
}

.btn-outline-primary {
  color: var(--e-global-color-primary);
}

.btn-primary,
.btn-outline-primary,
.btn-primary:focus,
.btn-outline-primary:focus {
  border-color: var(--e-global-color-primary);
}

.alert-primary,
.bg-primary,
.btn-primary,
.btn-primary:focus {
  background-color: var(--e-global-color-primary);
}

.bg-primary-light {
  background-color: var(--e-global-color-primary-light);
}

.btn-outline-secondary {
  color: var(--e-global-color-secondary);
}

.btn-secondary,
.btn-outline-secondary {
  border-color: var(--e-global-color-secondary);
}

.bg-secondary,
.btn-secondary {
  background-color: var(--e-global-color-secondary) !important;
}

.btn-outline-success,
.badge-white {
  color: var(--e-global-color-success);
}

.btn-success,
.btn-outline-success {
  border-color: var(--e-global-color-success);
}

.btn-success {
  background-color: var(--e-global-color-success);
}

.up-nav {
  box-shadow: 0 13px 16px -10px rgba(0, 0, 0, 0.1);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.up-logo-img {
  width: 300px;
  cursor: pointer;
}

.neutral-link,
.neutral-link:hover,
.neutral-link:visited {
  color: var(--e-global-color-text);
  text-decoration: none;
}

.up-login-box {
  min-width: 250px;
}

.up-menu-item {
  position: relative;
  padding: 13px 20px;
  line-height: 50px;
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.4s;
}

.up-menu-item:hover {
  color: var(--e-global-color-accent);
}

.up-menu-item:hover::after,
.up-menu-item.active::after {
  content: "";
  background-color: var(--e-global-color-success);
  position: absolute;
  display: block;
  height: 3px;
  width: 100%;
  left: 0;
  bottom: 0;
  transition: 0.3s;
  transition-timing-function: cubic-bezier(0.58, 0.3, 0.005, 1);
}

.up-menu-item.active {
  color: var(--e-global-color-accent);
}

.up-title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-size: var(--e-global-typography-primary-font-size-md);
  font-weight: var(--e-global-typography-primary-font-weight);
  text-transform: uppercase;
}

.up-title img {
  height: 27px;
  width: 24px;
  padding-bottom: 3px;
}

.up-slide {
  height: 350px;
}

.up-slide-full-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-height: 350px;
}

.up-card.card {
  border-width: 5px 0 0 0;
  border-color: var(--e-global-color-success);
  box-shadow: 0 5px 40px -10px rgba(0, 0, 0, 0.16);
}

.up-card-h.up-card.card {
  border-width: 0 0 0 5px;
}

.up-card .card-img-top {
  max-height: 225px;
  object-fit: cover;
  filter: brightness(95%);
}

.up-card .card-title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-size: 18px;
  font-weight: var(--e-global-typography-primary-font-weight);
  text-transform: uppercase;
  text-align: center;
}
.up-card.up-card-h .card-title {
  text-align: left;
}

.up-home-card .card-img {
  max-height: 100px;
  object-fit: scale-down;
}

.up-home-card .card-text {
  max-height: 66px;
  overflow: hidden;
}

.up-bullet-points {
  background-color: var(--e-global-color-secondary);
}

.up-bullet-points ul {
  list-style-type: square;
  padding-left: 30px;
}

.wd-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-attachment: fixed;
  opacity: 0;
  transition: all 0.4s ease-in-out;
  background-color: var(--e-global-color-primary);
}

.wd-masked-view:hover .wd-mask {
  opacity: 95%;
}

.up-box {
  padding: 35px;
  text-align: center;
}

.up-box-large {
  padding: 65px;
}

.up-box-primarily {
  background-color: var(--e-global-color-primary);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.up-box-action {
  background-color: var(--e-global-color-secondary);
  transition: all 0.4s;
}

.up-box-header {
  text-transform: var(--e-global-typography-primary-text-transform);
  margin-bottom: 15px;
}

.up-box-large .up-box-header {
  margin-bottom: 20px;
}

.up-box-primarily .up-box-header {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-size: var(--e-global-typography-primary-font-size-sm);
  font-weight: var(--e-global-typography-primary-font-weight);
  color: #ffffff;
}

.up-box-action .up-box-header {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-size: var(--e-global-typography-secondary-font-size-sm);
  font-weight: var(--e-global-typography-secondary-font-weight);
  color: #ffffff;
}

.up-box-body {
  margin-bottom: 15px;
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size-md);
  font-weight: var(--e-global-typography-text-font-weight);
}

.up-box-action .up-box-body,
.up-box-primarily .up-box-body {
  color: #ffffff;
}

.up-box-footer {
  width: 100%;
  margin: 0;
}

.up-box-action .up-box-footer {
  color: #ffffff;
}

.up-btn {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-size: var(--e-global-typography-accent-font-size);
  font-weight: var(--e-global-typography-accent-font-weight);
  background: transparent;
  border: 2px solid;
  padding: 10px 30px 10px 30px;
  text-decoration: none;
  display: inline-block;
  line-height: 1;
  cursor: pointer;
}

.up-box-action .up-btn {
  border-color: #fff;
  color: #ffffff;
}

.up-footer {
  background-color: var(--e-global-color-footer);
  padding: 40px 10px;
}

.up-footer-top {
  border-style: dotted;
  border-width: 0 0 1px 0;
  border-color: #9b9b9b;
}

.up-footer-menu {
  padding: 10px;
}

.up-footer-menu > div {
  padding: 13px 15px;
  margin-left: 2.5px;
}

.up-footer-menu a {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  color: var(--e-global-color-secondary);
  font-size: var(--e-global-typography-text-font-size-sm);
  font-weight: var(--e-global-typography-accent-font-weight);
  text-decoration: none;
  text-transform: var(--e-global-typography-primary-text-transform);
}

.up-footer-menu a:not([href]) {
  color: var(--e-global-color-secondary);
}

.up-footer-menu a:hover {
  color: #000000;
}

.up-footer-contact {
  padding: 10px;
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
}

.up-footer-contact > div {
  padding-left: 10px;
  padding-right: 10px;
}

.up-footer-icon {
  font-size: var(--e-icon-list-icon-size);
}

.up-footer-bottom {
  margin-top: 15px;
  padding: 10px;
}

.btn-outline-admin {
  color: red;
  background-color: transparent;
}

.btn-outline-admin:hover {
  color: white;
  background-color: red;
  border-color: red;
}

.nav-settings .btn-outline-primary,
.nav-settings .btn-outline-secondary,
.nav-settings .btn-outline-admin {
  border-color: transparent;
}

.title-line {
  line-height: 1.1;
  padding-bottom: 10px;
}

.clickable {
  cursor: pointer;
}

.img-scale-down {
  object-fit: scale-down !important;
  filter: unset !important;
}

.body-type-header {
  font-size: var(--e-global-typography-text-font-size-md);
}

.body-type-header img {
  height: 21px;
  width: 18px;
  padding-bottom: 3px;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }

  .print-full-width {
    width: 100% !important;
    max-width: 100% !important;
  }
}
